<template>

  <user-form
    :title="title"
    :submit-button-text="submitButtonText"
    @submitUserForm="onSubmit"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UserForm from './UserForm.vue'

export default {
  components: {
    UserForm,
  },
  data() {
    return {
      title: "Ajout d'un utilisateur",
      submitButtonText: 'Ajouter',
    }
  },
  setup() {
    const toast = useToast()

    const onSubmit = formUserValue => {
      store.dispatch('users/addUser', formUserValue)
        .then(() => {
          router.push({ name: 'users-list' })
        })
        .catch(error => {
          if (error.status === 409) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Adresse Email déjà existante',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: "Erreur lors de l'ajout d'un utilisateur",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    return {
      onSubmit,
    }
  },
}
</script>
